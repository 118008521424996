<div class="menu-item px-3">
  <div class="menu-content text-muted pb-2 px-3 fs-7 text-uppercase">
    Payments
  </div>
</div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer"> Create Invoice </a>
</div>

<div class="menu-item px-3">
  <a class="menu-link flex-stack px-3 cursor-pointer">
    Create Payment
    <i
      class="fas fa-exclamation-circle ms-2 fs-7"
      data-bs-toggle="tooltip"
      title="Specify a target name for future usage and reference"
    ></i>
  </a>
</div>

<div class="menu-item px-3">
  <a class="menu-link px-3 cursor-pointer"> Generate Bill </a>
</div>

<div
  class="menu-item px-3"
  data-kt-menu-trigger="hover"
  data-kt-menu-placement="left-start"
  data-kt-menu-flip="center, top"
>
  <a class="menu-link px-3 cursor-pointer">
    <span class="menu-title">Subscription</span>
    <span class="menu-arrow"></span>
  </a>

  <div class="menu-sub menu-sub-dropdown w-175px py-4">
    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Plans </a>
    </div>

    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Billing </a>
    </div>

    <div class="menu-item px-3">
      <a class="menu-link px-3 cursor-pointer"> Statements </a>
    </div>

    <div class="separator my-2"></div>

    <div class="menu-item px-3">
      <div class="menu-content px-3">
        <label
          class="form-check form-switch form-check-custom form-check-solid"
        >
          <input
            class="form-check-input w-30px h-20px"
            type="checkbox"
            value="1"
            checked
            name="notifications"
          />

          <span class="form-check-label text-muted fs-6">Recuring</span>
        </label>
      </div>
    </div>
  </div>
</div>

<div class="menu-item px-3 my-1">
  <a class="menu-link px-3 cursor-pointer"> Settings </a>
</div>
