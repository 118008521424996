import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tables-widget14',
  templateUrl: './tables-widget14.component.html',
  styleUrls: ['./tables-widget14.component.scss']
})
export class TablesWidget14Component implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
