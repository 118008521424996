import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-tables-widget12',
  templateUrl: './tables-widget12.component.html',
})
export class TablesWidget12Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
