import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-tiles-widget14',
  templateUrl: './tiles-widget14.component.html',
})
export class TilesWidget14Component {
  @Input() cssClass = '';
  constructor() {}
}
