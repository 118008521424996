<div class="card-header flex-nowrap border-0 pt-9">
  <div class="card-title m-0">
    <div class="symbol symbol-45px w-45px bg-light me-5">
      <img [src]="image" alt="Metronic" class="p-3" />
    </div>

    <a class="fs-4 fw-bold text-hover-primary text-gray-600 m-0 cursor-pointer">
      {{ title }}
    </a>
  </div>

  <div class="card-toolbar m-0">
    <button
      type="button"
      class="
        btn btn-clean btn-sm btn-icon btn-icon-primary btn-active-light-primary
        me-n3
      "
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-3 svg-icon-primary"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
  </div>
</div>

<div class="card-body d-flex flex-column px-9 pt-6 pb-8">
  <div class="fs-2tx fw-bolder mb-3">{{ description }}</div>

  <div class="d-flex align-items-center flex-wrap mb-5 mt-auto fs-6">
    <ng-container *ngIf="status === 'up'">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/arr007.svg'"
        class="svg-icon svg-icon-3 me-1 svg-icon-success"
      ></span>
    </ng-container>

    <ng-container *ngIf="status === 'down'">
      <span
        [inlineSVG]="'./assets/media/icons/duotune/arrows/arr006.svg'"
        class="svg-icon svg-icon-3 me-1 svg-icon-danger"
      ></span>
    </ng-container>

    <div
      class="fw-bolder me-2"
      [ngClass]="status === 'up' ? 'text-success' : 'text-danger'"
    >
      {{ status === "up" ? "+" : "-" }}
      {{ statusValue }}%
    </div>

    <div class="fw-bold text-gray-400">{{ statusDesc }}</div>
  </div>

  <div class="d-flex align-items-center fw-bold">
    <span class="badge bg-light text-gray-700 px-3 py-2 me-2"
      >{{ progress }}%</span
    >
    <span class="text-gray-400 fs-7">{{ progressType }}</span>
  </div>
</div>
