<div
  class="d-flex flex-column flex-column-fluid bgi-position-y-bottom position-x-center bgi-no-repeat bgi-size-contain bgi-attachment-fixed"
>
  <!--begin::Content-->
  <div class="d-flex flex-center flex-column flex-column-fluid pb-lg-20">
    <!--begin::Content body-->
    <div class="mx-auto">
      <div class="container">
        <div class="d-flex py-5 align-items-center justify-content-center">
          <img
            class="img-fluid"
            src="assets/imgs/logo-socialpay.png"
            alt="socialpay-logo"
          />
          <div class="d-block textlogo">
            <h1>SOCIAL</h1>
            <h1>PAY</h1>
          </div>
        </div>

        <div class="row mx-auto">
          <div class="col-xxl-12 mx-auto my-auto">
            <div class="card shadow-sm">
              <div
                class="card-header text-center align-items-center justify-content-center"
              >
                <h4 class="card-title mb-4">Payment Details</h4>
              </div>
              <div class="">
                <div class="row m-0 p-0">
                  <div class="col-md-6 bg-light p-0 pt-8">
                    <div class="px-15 mb-7">
                      <img
                        [src]="payment_page_details?.payment_image"
                        class="img-fluid rounded-3"
                        alt="paymentpage image"
                        onerror="this.onerror=null;this.src='assets/imgs/background.jpg';"
                        style="çç"
                      />
                    </div>
                    <div class="px-15">
                      <div class="col-md-12">
                        <h3
                          class="price"
                          *ngIf="selected_name === 'Social Pay'"
                        >
                          {{ total }}
                          {{ payment_page_details?.cryptocurrency_type }}
                          <span
                            *ngIf="
                              payment_page_details?.payment_type === 'recurring'
                            "
                          >
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval == 1
                              "
                            >
                              /
                              {{
                                payment_page_details?.recurring_config
                                  .interval_type | titlecase
                              }}
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'daily'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Days
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'weekly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Weeks
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'monthly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Months
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'yearly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Years
                            </span>
                          </span>
                        </h3>
                        <h3
                          class="price"
                          *ngIf="selected_name === 'Stripe Connect'"
                        >
                          ${{ total }}
                          <span
                            *ngIf="
                              payment_page_details?.payment_type === 'recurring'
                            "
                          >
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval == 1
                              "
                            >
                              /
                              {{
                                payment_page_details?.recurring_config
                                  .interval_type | titlecase
                              }}
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'daily'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Days
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'weekly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Weeks
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'monthly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Months
                            </span>
                            <span
                              *ngIf="
                                payment_page_details?.recurring_config
                                  .interval > 1 &&
                                payment_page_details?.recurring_config
                                  .interval_type == 'yearly'
                              "
                            >
                              / Every
                              {{
                                payment_page_details?.recurring_config.interval
                              }}
                              Years
                            </span>
                          </span>
                        </h3>
                      </div>
                    </div>
                    <hr />
                    <div class="px-15 my-7">
                      <div class="row">
                        <div class="col-md-12">
                          <h3>{{ payment_page_details?.page_name }}</h3>
                        </div>
                        <div class="col-12 mt-5" *ngIf="payment_page_details">
                          <div class="">
                            <span
                              [innerHTML]="payment_page_details?.description"
                            ></span>
                          </div>
                          <!-- <div
                            class="description"
                            [class.show]="show"
                            [innerHTML]="payment_page_details?.description"
                          ></div>

                        <div class="col-12">
                          <p class="description" [class.show]="show" [innerHTML]="payment_page_details?.description">
                          </p>

                          <a *ngIf="payment_page_details?.description.length > 200" class="cursor color-primary"
                            (click)="show = !show">
                            {{ show ? 'Show less': 'Show More' }}
                          </a> -->
                          <!-- <span class="collapsable float-left">
                             {{
                              convertToPlainText((info.length>firstCount) ? (info | slice:0:counter)+'..':(info))
                             }}
                            <span class="redTxt cursor color-primary" *ngIf="(info.length>100)" (click)="toggleSkil($event)"  >
                                {{showTxt}}
                             </span>
                          </span> -->
                        </div>
                      </div>
                    </div>
                    <!--
                    <p class="price mt-5 mb-2">
                    {{ total }} {{ payment_page_details?.cryptocurrency_type }}
                    </p>
                    -->
                    <!-- <h4 class="bold-title mb-2">
                    <span class="">{{ payment_page_details?.page_name }}</span>
                  </h4> -->
                    <!--
                  <p class="light-title text-muted">
                    {{ payment_page_details?.description }}
                  </p>
                   -->
                  </div>
                  <div class="col-md-6 px-10 py-7">
                    <ng-container
                      *ngIf="payment_page_details?.transaction_limit !== 'true'"
                    >
                      <form class="needs-validation" [formGroup]="checkoutForm">
                        <!-- compulsory fields section start -->
                        <ng-container
                          *ngIf="
                            payment_page_details?.compulsory_fields?.length > 0
                          "
                        >
                          <div
                            class="mb-3"
                            *ngFor="
                              let item of payment_page_details?.compulsory_fields
                            "
                          >
                            <label class="form-label" for="firstName">
                              {{ strReplace(item | uppercase) }}</label
                            >
                            <!-- <input name="email" type="email" *ngIf="item === 'email'" formControlName="email"
                              value="12345" class="form-control"> -->
                            <input
                              type="text"
                              class="form-control"
                              id="firstName"
                              placeholder="Enter {{
                                strReplace(item | titlecase)
                              }}"
                              [name]="item"
                              formControlName="{{ item }}"
                              required
                            />
                          </div>
                        </ng-container>
                        <!-- ngcontainer section start -->
                        <ng-container
                          formGroupName="custom_fields"
                          *ngIf="
                            payment_page_details?.custom_fields?.length !== 0
                          "
                        >
                          <!-- <h2 class="my-2">Custom fields</h2> -->
                          <div
                            class="mb-1"
                            *ngFor="
                              let item of payment_page_details?.custom_fields
                            "
                          >
                            <label class="form-label" for="address">
                              {{ strReplace(item | titlecase) }}</label
                            >
                            <input
                              type="text"
                              class="form-control"
                              placeholder="Enter {{
                                strReplace(item | titlecase)
                              }}"
                              [name]="item"
                              formControlName="{{ item }}"
                              [disabled]="disabled"
                            />
                          </div>
                        </ng-container>
                        <!-- ngcontainer section ends -->
                        <!-- Payment processor type section start -->
                        <div class="row col-md-12 mt-2 d-flex">
                          <label for="">SELECT PREFERRED PAYMENT METHOD</label>
                          <div
                            class="col mb-3 my-3"
                            *ngFor="
                              let item of payment_page_details?.payment_option
                            "
                          >
                            <div class="box" (click)="uploader.click()">
                              <ng-container *ngIf="item?.name === 'Social Pay'">
                                <ng-container *ngIf="color_mode === 'light'">
                                  <img
                                    src="assets/imgs/logo-dark-socialpay.png"
                                    width="101px"
                                    alt="social pay logo"
                                  />
                                </ng-container>
                                <ng-container *ngIf="color_mode === 'dark'">
                                  <img
                                    src="{{ item?.logo_image }}"
                                    width="101px"
                                    alt="social pay logo"
                                  />
                                </ng-container>
                              </ng-container>
                              <ng-container
                                *ngIf="item?.name === 'Stripe Connect'"
                              >
                                <img
                                  src="{{ item?.logo_image }}"
                                  width="101px"
                                  alt="Stripe logo"
                                />
                              </ng-container>
                              <label class="radio-button-container">
                                <input
                                  type="radio"
                                  #uploader
                                  value="{{ item?.id }}"
                                  id="{{ item?.id }}"
                                  (change)="paymentProcessor($event, item)"
                                  name="radio"
                                />
                                <span class="checkmark"></span>
                              </label>
                              <label for="{{ item?.id }}"></label>
                            </div>
                          </div>
                        </div>
                        <!-- Payment processor type section ends -->
                        <div class="my-5">
                          <!-- Social pay button -->
                          <button
                            *ngIf="selectedMethod?.name === 'Social Pay'"
                            class="btn btn-primary btn-lg btn-block"
                            type="button"
                            style="width: 100%"
                            (click)="onSubmit()"
                          >
                            <ng-container *ngIf="isLoading$$ | async">
                              <span
                                class="indicator-progress"
                                [style.display]="'block'"
                              >
                                Please wait...
                                <span
                                  class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                              </span>
                            </ng-container>
                            <ng-container
                              *ngIf="(isLoading$$ | async) === false"
                            >
                              <span class="indicator-label">Checkout</span>
                            </ng-container>
                          </button>
                          <!-- stripe button -->
                          <button
                            *ngIf="selectedMethod?.name === 'Stripe Connect'"
                            class="btn btn-primary btn-lg btn-block"
                            type="button"
                            style="width: 100%"
                            (click)="onSubmitStripe()"
                          >
                            <ng-container *ngIf="isLoading$$ | async">
                              <span
                                class="indicator-progress"
                                [style.display]="'block'"
                              >
                                Please wait...
                                <span
                                  class="spinner-border spinner-border-sm align-middle ms-2"
                                ></span>
                              </span>
                            </ng-container>
                            <ng-container
                              *ngIf="(isLoading$$ | async) === false"
                            >
                              <span class="indicator-label">Checkout</span>
                            </ng-container>
                          </button>
                        </div>
                      </form>
                    </ng-container>
                    <ng-container
                      *ngIf="
                        payment_page_details?.transaction_limit_checkout ===
                        'true'
                      "
                    >
                      <div class="text-center">
                        <div
                          class="d-flex flex-row align-items-center justify-content-center"
                        >
                          <div class="d-block text-center">
                            <img
                              src="assets/empty-screen/creator_empty.svg"
                              alt=""
                            />
                            <p class="empty-text card-label">Item Sold out.</p>
                          </div>
                        </div>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--end::Content body-->
  </div>
  <!--end::Content-->

  <!-- begin::Footer -->
  <div class="d-flex flex-center flex-column-auto p-10">
    <div class="row">
      <div class="col-12 text-center">
        <div class="d-block">
          <p class="mb-1">
            <span class="text-muted fw-bold me-2"
              >{{ currentDateStr }} &copy;</span
            >
            <a class="text-gray-800 text-hover-primary cursor-pointer">
              Social connector
            </a>
          </p>
        </div>
      </div>
      <div class="col-12 text-center">
        <div class="d-block">
          <ul class="list-inline">
            <li class="list-inline-item">
              <a
                href="https://docs.socialconnector.io/welcome-to-social-connector"
                target="_blank"
                class="menu-link pe-0 pe-2 cursor-pointer"
              >
                Doc
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="mailto:team@digitalmarketing.org"
                target="_blank"
                class="menu-link pe-0 pe-2 cursor-pointer"
              >
                Contact
              </a>
            </li>
            <li class="list-inline-item">
              <a
                href="https://discord.gg/YERX2dNAGS"
                target="_blank"
                class="menu-link pe-0 pe-2 cursor-pointer"
              >
                Support
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
  <!-- end::Footer -->
</div>
