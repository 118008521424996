import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-feeds-widget5',
  templateUrl: './feeds-widget5.component.html',
})
export class FeedsWidget5Component implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
