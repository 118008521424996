<div class="modal" [ngClass]="{'active': showModal}">
  <div class="modal-body my-3">
    <div>
      <span 
        class="close-button" 
        (click)="closeModal()">
        &times;
      </span>
      <h3 
        class="createTower" 
        [innerHTML]="editTowerModal ? 'Update Tower' : 'Create Tower'">
      </h3>
  
    </div>
    <div class="container">
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        > 
          Tower Name *
        </label>
        <input 
          type="text" 
          required 
          [(ngModel)]="towerTitle" 
          (change)="onTitleChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="my tower"
           name="title">
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Description *
        </label>
        <textarea 
          [(ngModel)]="towerDescription" 
          required 
          (change)="onDescChange($event)" 
          class="form-control form-control-lg form-control-solid textarea" 
          placeholder="description"
          name="description" 
          rows="4" 
          cols="50">
        </textarea>
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Trait Type *
        </label>
            <select 
            class="form-control form-control-lg form-control-solid" 
            [(ngModel)]="floorValue" 
            #mySelect 
            (change)="check(mySelect.value)"
          >
            <option value="">select..</option>
            <option *ngFor="let item of NFTTraits"
              #mySelect2
              (change)="check(mySelect2.value)"
              [value]="item?.key"
            >
              {{item?.key}}
            </option>
          </select>
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Trait Value *
        </label>
          <select 
            class="form-control form-control-lg form-control-solid" 
            [(ngModel)]="floorPrice" 
            #mySelect2
          >
            <option value="">select..</option>
            <option
              [value]="floorPrice"
            >
              {{floorPrice}}
            </option>
          </select>
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Required Gary *
        </label>
        <input 
          type="number" 
          required 
          [(ngModel)]="requiredGary" 
          (change)="onGaryChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="0"
          name="required_gary"
        >
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Required Girraffe *
        </label>
        <input 
          type="number" 
          required 
          [(ngModel)]="requiredGiraffe" 
          (change)="onGiraffeChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="0"
          name="required_giraffe"
        >
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Segments *
        </label>
        <ng-multiselect-dropdown 
          [placeholder]="'select segment...'" [settings]="dropdownSettings3"
          [(ngModel)]="selectedSegmentsName" [data]="allSegments"
          (onSelect)="onSegmentSelect($event)" (onSelectAll)="onSelectAll($event)"
        >
        </ng-multiselect-dropdown>
      </div>
      <div>
        <label 
          class="col-form-label fw-bold fs-6"
        >
          Link *
        </label>
        <input 
          type="text" 
          required 
          [(ngModel)]="towerLink" 
          (change)="onLinkChange($event)" 
          class="form-control form-control-lg form-control-solid" 
          placeholder="https://"
          name="link"
        >
      </div>
      <div class="btn-styling">
        <div *ngIf="editTowerModal; else createButton">
          <button 
            type="button" 
            class="btn btn-block btn-gradient mr-1 mb-5" 
            (click)="handleUpdateTower()"
          >
            Update Tower
          </button>
        </div>
        <ng-template #createButton>
          <button 
            type="button" 
            class="btn btn-block btn-gradient mr-1 mb-5" 
            (click)="createTower()"
          >
            Create Tower
          </button>
        </ng-template>
      </div>
    </div>
  </div>
</div>
<!-- <div class="modal" [ngClass]="{'active': showModal}">
  <div class="modal-content">
    <span 
      class="close-button" 
      (click)="closeModal()">
      &times;
    </span>
    <div>
      <h3 
        class="createTower" 
        [innerHTML]="editTowerModal ? 'Update Tower' : 'Create Tower'">
      </h3>
      <div class="mt-5 mb-5">
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          > 
            Tower Name *
          </label>
          <input 
            type="text" 
            required 
            [(ngModel)]="towerTitle" 
            (change)="onTitleChange($event)" 
            class="form-control form-control-lg form-control-solid" 
            placeholder="my tower"
             name="title">
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Description *
          </label>
          <textarea 
            [(ngModel)]="towerDescription" 
            required 
            (change)="onDescChange($event)" 
            class="form-control form-control-lg form-control-solid textarea" 
            placeholder="description"
            name="description" 
            rows="4" 
            cols="50">
          </textarea>
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Trait Type *
          </label>
              <select 
              class="form-control form-control-lg form-control-solid" 
              [(ngModel)]="floorValue" 
              #mySelect 
              (change)="check(mySelect.value)"
            >
              <option value="">select..</option>
              <option *ngFor="let item of NFTTraits"
                #mySelect2
                (change)="check(mySelect2.value)"
                [value]="item?.key"
              >
                {{item?.key}}
              </option>
            </select>
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Trait Value *
          </label>
            <select 
              class="form-control form-control-lg form-control-solid" 
              [(ngModel)]="floorPrice" 
              #mySelect2
            >
              <option value="">select..</option>
              <option
                [value]="floorPrice"
              >
                {{floorPrice}}
              </option>
            </select>
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Required Gary *
          </label>
          <input 
            type="number" 
            required 
            [(ngModel)]="requiredGary" 
            (change)="onGaryChange($event)" 
            class="form-control form-control-lg form-control-solid" 
            placeholder="0"
            name="required_gary"
          >
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Required Girraffe *
          </label>
          <input 
            type="number" 
            required 
            [(ngModel)]="requiredGiraffe" 
            (change)="onGiraffeChange($event)" 
            class="form-control form-control-lg form-control-solid" 
            placeholder="0"
            name="required_giraffe"
          >
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Segments *
          </label>
          <ng-multiselect-dropdown 
            [placeholder]="'select segment...'" [settings]="dropdownSettings3"
            [(ngModel)]="selectedSegmentsName" [data]="allSegments"
            (onSelect)="onSegmentSelect($event)" (onSelectAll)="onSelectAll($event)"
          >
          </ng-multiselect-dropdown>
        </div>
        <div>
          <label 
            class="col-form-label fw-bold fs-6"
          >
            Link *
          </label>
          <input 
            type="text" 
            required 
            [(ngModel)]="towerLink" 
            (change)="onLinkChange($event)" 
            class="form-control form-control-lg form-control-solid" 
            placeholder="https://"
            name="link"
          >
        </div>
        <div class="btn-styling">
          <div *ngIf="editTowerModal; else createButton">
            <button 
              type="button" 
              class="btn btn-block btn-gradient mr-1 mb-5" 
              (click)="handleUpdateTower()"
            >
              Update Tower
            </button>
          </div>
          <ng-template #createButton>
            <button 
              type="button" 
              class="btn btn-block btn-gradient mr-1 mb-5" 
              (click)="createTower()"
            >
              Create Tower
            </button>
          </ng-template>
        </div>
      </div>
    </div>
  </div>
</div> -->