import { Component, OnInit, Input, ChangeDetectionStrategy } from '@angular/core';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-buySOL',
  templateUrl: './buySOL.component.html',
  styleUrls: ['./buySOL.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class BuySOLComponent implements OnInit {
  @Input() showSOLModal: boolean = false;
  @Input() iframeSrc: string = '';

  constructor(private sanitizer: DomSanitizer,) { }

  ngOnInit() {
  }

  closeSOLModal() {
    this.showSOLModal = !this.showSOLModal;
  }

  safeIframeSrc(): SafeResourceUrl {

    return this.sanitizer.bypassSecurityTrustResourceUrl(this.iframeSrc);
  }

}
