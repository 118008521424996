import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HttpClientModule } from '@angular/common/http';
import { HttpClientInMemoryWebApiModule } from 'angular-in-memory-web-api';
import { ClipboardModule } from 'ngx-clipboard';
import { TranslateModule } from '@ngx-translate/core';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthService } from './modules/auth/services/auth.service';
import { environment } from 'src/environments/environment';
import { LoadingBarHttpClientModule } from '@ngx-loading-bar/http-client';
import { LoadingBarModule } from '@ngx-loading-bar/core';
import { HdWalletAdapterModule } from '@heavy-duty/wallet-adapter';
import { ToastService, AngularToastifyModule } from 'angular-toastify';
// #fake-start#
import { FakeAPIService } from './_fake/fake-api.service';
import { authInterceptorProviders } from './modules/auth/interceptor';
import { CheckoutComponent } from './pages/checkout/checkout.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { PayComponent } from './pages/checkout/pay/pay.component';
import { NgxQrcodeStylingModule } from 'ngx-qrcode-styling';
import { InlineCheckoutComponent } from './pages/inline-checkout/inline-checkout.component';
import { InlinePayComponent } from './pages/inline-pay/inline-pay.component';
import { TermsconditionComponent } from './pages/termscondition/termscondition.component';
import { TransactionsDetailsComponent } from './pages/transactions-details/transactions-details.component';
import { NgxTwitterWidgetsModule } from "ngx-twitter-widgets";
import { SegmentsComponent } from './modules/segments/segments.component';
import { ViewComponent } from './pages/view/view.component';
import { MigrationCheckerComponent } from './pages/migration-checker/migration-checker.component';
import { SortablejsModule } from 'ngx-sortablejs';
import { TrackPowComponent } from './pages/track-pow/track-pow.component';
import { LoaderModalComponent, MintComponent } from './pages/mint/mint.component';
import { FeesComponent } from './pages/fees/fees.component';
import { LandingPageComponent } from './modules/landing-page/landing-page.component';
import { TowersModule } from './modules/towers/towers.module';
import { CompleteregModule } from './modules/completereg/completereg.module';

// #fake-end#

function appInitializer(authService: AuthService) {
  return () => {
    return new Promise((resolve) => {
      //@ts-ignore
      authService.getUserByToken().subscribe().add(resolve);
    });
  };
}

@NgModule({
  declarations: [
    AppComponent,
    CheckoutComponent,
    TermsconditionComponent,
    PayComponent,
    InlineCheckoutComponent,
    InlinePayComponent, TransactionsDetailsComponent, SegmentsComponent,
    ViewComponent,
    MigrationCheckerComponent,
    TrackPowComponent,
    MintComponent,
    LoaderModalComponent,
    FeesComponent,
    LandingPageComponent,
  ],
  imports: [
    SortablejsModule.forRoot({ animation: 150 }),
    BrowserModule,
    BrowserAnimationsModule,
    TranslateModule.forRoot(),
    HdWalletAdapterModule.forRoot({ autoConnect: true }),
    HttpClientModule,
    ClipboardModule,
    LoadingBarModule,
    LoadingBarHttpClientModule,
    NgxQrcodeStylingModule,
    NgxTwitterWidgetsModule,
    AngularToastifyModule,
    // #fake-start#
    environment.isMockEnabled
      ? HttpClientInMemoryWebApiModule.forRoot(FakeAPIService, {
        passThruUnknownUrl: true,
        dataEncapsulation: false,
      })
      : [],
    // #fake-end#
    AppRoutingModule,
    TowersModule,
    CompleteregModule,
    InlineSVGModule.forRoot(),
    NgbModule,
    ReactiveFormsModule,
    FormsModule,

  ],
  providers: [
    ToastService,
    authInterceptorProviders,
    {
      provide: APP_INITIALIZER,
      useFactory: appInitializer,
      multi: true,
      deps: [AuthService],
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule { }
