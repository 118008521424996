<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Tasks Overview</span>
    <span class="text-muted fw-bold fs-7">Pending 10 tasks</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <!-- begin::Menu 1 -->
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu 1 -->
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive">
    <!-- begin::Table -->
    <table class="table align-middle gs-0 gy-5">
      <!-- begin::Table head -->
      <thead>
        <tr>
          <th class="p-0 w-50px"></th>
          <th class="p-0 min-w-200px"></th>
          <th class="p-0 min-w-100px"></th>
          <th class="p-0 min-w-40px"></th>
        </tr>
      </thead>
      <!-- end::Table head -->
      <!-- begin::Table body -->
      <tbody>
        <tr>
          <th>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/plurk.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </th>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Top Authors
            </a>
            <span class="text-muted fw-bold d-block fs-7"
              >Successful Fellas</span
            >
          </td>
          <td>
            <div class="d-flex flex-column w-100 me-2">
              <div class="d-flex flex-stack mb-2">
                <span class="text-muted me-2 fs-7 fw-bold">70%</span>
              </div>
              <div class="progress h-6px w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  [style.width]="'70%'"
                ></div>
              </div>
            </div>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <th>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/telegram.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </th>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Popular Authors
            </a>
            <span class="text-muted fw-bold d-block fs-7">Most Successful</span>
          </td>
          <td>
            <div class="d-flex flex-column w-100 me-2">
              <div class="d-flex flex-stack mb-2">
                <span class="text-muted me-2 fs-7 fw-bold">50%</span>
              </div>
              <div class="progress h-6px w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  [style.width]="'50%'"
                ></div>
              </div>
            </div>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <th>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/vimeo.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </th>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              New Users
            </a>
            <span class="text-muted fw-bold d-block fs-7">Awesome Users</span>
          </td>
          <td>
            <div class="d-flex flex-column w-100 me-2">
              <div class="d-flex flex-stack mb-2">
                <span class="text-muted me-2 fs-7 fw-bold">80%</span>
              </div>
              <div class="progress h-6px w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  [style.width]="'80%'"
                ></div>
              </div>
            </div>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <th>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/bebo.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </th>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Active Customers
            </a>
            <span class="text-muted fw-bold d-block fs-7">Best Customers</span>
          </td>
          <td>
            <div class="d-flex flex-column w-100 me-2">
              <div class="d-flex flex-stack mb-2">
                <span class="text-muted me-2 fs-7 fw-bold">90%</span>
              </div>
              <div class="progress h-6px w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  [style.width]="'90%'"
                ></div>
              </div>
            </div>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <th>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/kickstarter.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </th>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Bestseller Theme
            </a>
            <span class="text-muted fw-bold d-block fs-7"
              >Amazing Templates</span
            >
          </td>
          <td>
            <div class="d-flex flex-column w-100 me-2">
              <div class="d-flex flex-stack mb-2">
                <span class="text-muted me-2 fs-7 fw-bold">70%</span>
              </div>
              <div class="progress h-6px w-100">
                <div
                  class="progress-bar bg-primary"
                  role="progressbar"
                  [style.width]="'70%'"
                ></div>
              </div>
            </div>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
</div>
