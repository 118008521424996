<div class="card-body d-flex align-items-center pt-3 pb-0">
  <div class="d-flex flex-column flex-grow-1 py-2 py-lg-13 me-2">
    <a href="#" class="fw-bolder text-dark fs-4 mb-2 text-hover-primary">
      {{ title }}
    </a>

    <span class="fw-bold text-muted fs-5">
      <ng-content select="description"></ng-content>
    </span>
  </div>

  <img [src]="avatar" alt="" class="align-self-end h-100px" />
</div>
