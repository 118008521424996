import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { BehaviorSubject, finalize, map, Observable, Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
// import { TokenService } from '../../auth/services/token.service';
// @ts-ignore
const api = `${environment.social_pay_baseurl}`;
const apiUrl = `${environment.apiUrl}`;
const base_api = `${environment.base_api}`
// @ts-ignore
const gateway = `${environment.social_pay_gateway}`;
const httpOptions = {
  headers: new HttpHeaders({ 'Content-Type': 'application/json' }),
};
@Injectable({
  providedIn: 'root'
})
export class CompleteregService {

  constructor(
    private http: HttpClient,
    private router: Router,
  ) { }

  checkStakeStatus() {
    return this.http
      .get(`${apiUrl}/solana/stake/status`, httpOptions)
      .pipe();
  }

  checkActivationStatus() {
    return this.http.get(`${apiUrl}/solana/stake/activation/status`).pipe()
  }

  activateStake() {
    return this.http
      .post(`${apiUrl}/solana/stake`, httpOptions)
      .pipe()
  }
  updateStakeDetails(data) {
    return this.http.patch(`${apiUrl}/solana/stake/update/stake`, data).pipe()
  }

  createPerks(data) {
    return this.http.post(`${apiUrl}/solana/stake/create/perk`, data).pipe()
  }
  listPerks() {
    return this.http.get(`${apiUrl}/solana/stake/all/perks`).pipe()
  }
  updatPerk(id, data) {
    return this.http.patch(`${apiUrl}/solana/stake/update/perk/${id}`, data).pipe()
  }
  deletePerk(id) {
    return this.http.get(`${apiUrl}/solana/stake/delete/perk/${id}`)
  }
  getTransactions(page, search) {
    let url = `${apiUrl}/solana/stake/all/purchases`;

    if (page !== null && page !== undefined) {
      url += `?page=${page}`;
    }
    if (search !== null && search !== undefined && search !== '') {
      url += `&search=${search}`;
    }
    return this.http.get(url).pipe()
  }
  getsupporters(page, search, filter) {
    let url = `${apiUrl}/solana/stake/supporters`;


    if (page !== null && page !== undefined) {
      url += `?page=${page}`;
    }

    if (filter !== null && filter !== undefined && filter !== '') {
      url += `&filter=${filter}`;
    }

    if (search !== null && search !== undefined && search !== '') {
      if (page !== null && page !== undefined) {
        url += `&search=${search}`;
      } else {
        url += `?search=${search}`;
      }
    }

    return this.http.get(url, httpOptions).pipe()
  }
}
