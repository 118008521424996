<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Authors Earnings</span>
    <span class="text-muted mt-1 fw-bold fs-7">More than 400 new authors</span>
  </h3>
  <div class="card-toolbar">
    <ul class="nav">
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_6_tab_1')"
          (click)="setTab('kt_table_widget_6_tab_1')"
        >
          Month
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
            me-1
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_6_tab_2')"
          (click)="setTab('kt_table_widget_6_tab_2')"
        >
          Week
        </a>
      </li>
      <li class="nav-item">
        <a
          class="
            nav-link
            btn btn-sm btn-color-muted btn-active btn-active-light-primary
            fw-bolder
            px-4
          "
          data-bs-toggle="tab"
          [ngClass]="activeClass('kt_table_widget_6_tab_3')"
          (click)="setTab('kt_table_widget_6_tab_3')"
        >
          Day
        </a>
      </li>
    </ul>
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <div class="tab-content">
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_1')"
      id="kt_table_widget_6_tab_1"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/001-boy.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Brad Simmons
                </a>
                <span class="text-muted fw-bold d-block"
                  >Successful Fellas</span
                >
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$200,500</span>
              </td>
              <td class="text-end">
                <span class="text-primary fs-7 fw-bolder">+28%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/018-girl-9.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Jessie Clarcson
                </a>
                <span class="text-muted fw-bold d-block">HTML, CSS Coding</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$1,200,000</span>
              </td>
              <td class="text-end">
                <span class="text-warning fs-7 fw-bolder">+52%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/047-girl-25.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Jessie Clarcson
                </a>
                <span class="text-muted fw-bold d-block"
                  >PHP, Laravel, VueJS</span
                >
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$1,200,000</span>
              </td>
              <td class="text-end">
                <span class="text-danger fs-7 fw-bolder">+52%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/014-girl-7.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Natali Trump
                </a>
                <span class="text-muted fw-bold d-block">UI/UX Designer</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$3,400,000</span>
              </td>
              <td class="text-end">
                <span class="text-success fs-7 fw-bolder">-34%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/043-boy-18.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Kevin Leonard
                </a>
                <span class="text-muted fw-bold d-block">Art Director</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5"
                  >$35,600,000</span
                >
              </td>
              <td class="text-end">
                <span class="text-info fs-7 fw-bolder">+230%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_2')"
      id="kt_table_widget_6_tab_2"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/018-girl-9.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Jessie Clarcson
                </a>
                <span class="text-muted fw-bold d-block">HTML, CSS Coding</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$1,200,000</span>
              </td>
              <td class="text-end">
                <span class="text-warning fs-7 fw-bolder">+52%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/014-girl-7.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Natali Trump
                </a>
                <span class="text-muted fw-bold d-block">UI/UX Designer</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$3,400,000</span>
              </td>
              <td class="text-end">
                <span class="text-success fs-7 fw-bolder">-34%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/001-boy.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Brad Simmons
                </a>
                <span class="text-muted fw-bold d-block"
                  >Successful Fellas</span
                >
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$200,500</span>
              </td>
              <td class="text-end">
                <span class="text-primary fs-7 fw-bolder">+28%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
    <!-- begin::Tap pane -->
    <div
      class="tab-pane fade"
      [ngClass]="activeClass('kt_table_widget_6_tab_3')"
      id="kt_table_widget_6_tab_3"
    >
      <!-- begin::Table container -->
      <div class="table-responsive">
        <!-- begin::Table -->
        <table class="table align-middle gs-0 gy-3">
          <!-- begin::Table head -->
          <thead>
            <tr>
              <th class="p-0 w-50px"></th>
              <th class="p-0 min-w-150px"></th>
              <th class="p-0 min-w-140px"></th>
              <th class="p-0 min-w-120px"></th>
            </tr>
          </thead>
          <!-- end::Table head -->
          <!-- begin::Table body -->
          <tbody>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/047-girl-25.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Jessie Clarcson
                </a>
                <span class="text-muted fw-bold d-block">HTML, CSS Coding</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$1,200,000</span>
              </td>
              <td class="text-end">
                <span class="text-danger fs-7 fw-bolder">+52%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/014-girl-7.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Natali Trump
                </a>
                <span class="text-muted fw-bold d-block">UI/UX Designer</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$3,400,000</span>
              </td>
              <td class="text-end">
                <span class="text-success fs-7 fw-bolder">-34%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/043-boy-18.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Kevin Leonard
                </a>
                <span class="text-muted fw-bold d-block">Art Director</span>
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5"
                  >$35,600,000</span
                >
              </td>
              <td class="text-end">
                <span class="text-info fs-7 fw-bolder">+230%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
            <tr>
              <td>
                <div class="symbol symbol-50px me-2">
                  <span class="symbol-label">
                    <img
                      src="./assets/media/svg/avatars/001-boy.svg"
                      class="h-75 align-self-end"
                      alt=""
                    />
                  </span>
                </div>
              </td>
              <td>
                <a
                  href="#"
                  class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
                >
                  Brad Simmons
                </a>
                <span class="text-muted fw-bold d-block"
                  >Successful Fellas</span
                >
              </td>
              <td>
                <span class="text-muted fw-bold d-block fs-7">Paid</span>
                <span class="text-dark fw-bolder d-block fs-5">$200,500</span>
              </td>
              <td class="text-end">
                <span class="text-primary fs-7 fw-bolder">+28%</span>
              </td>
              <td class="text-end">
                <a
                  href="#"
                  class="
                    btn btn-sm btn-icon btn-bg-light btn-active-color-primary
                  "
                >
                  <span
                    [inlineSVG]="
                      './assets/media/icons/duotune/arrows/arr064.svg'
                    "
                    class="svg-icon svg-icon-2"
                  ></span>
                </a>
              </td>
            </tr>
          </tbody>
          <!-- end::Table body -->
        </table>
      </div>
      <!-- end::Table -->
    </div>
    <!-- end::Tap pane -->
  </div>
</div>
<!-- end::Body -->
