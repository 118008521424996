<div
  class="symbol symbol-35px symbol-circle"
  *ngFor="let user of users"
  [ngbTooltip]="user.name"
  placement="top"
>
  <ng-container *ngIf="user.avatar">
    <img [src]="user.avatar" alt="Pic" />
  </ng-container>
  <ng-container *ngIf="user.initials">
    <span class="symbol-label bg-primary text-inverse-primary fw-bolder">
      {{ user.initials }}
    </span>
  </ng-container>
</div>
