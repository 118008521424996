<div class="card-body">
  <a href="#" class="card-title fw-bolder text-muted text-hover-primary fs-4">
    {{ title }}
  </a>

  <div class="fw-bolder text-primary my-6">{{ time }}</div>

  <p class="text-dark-75 fw-bold fs-5 m-0">
    Create a headline that is informative<br />and will capture readers
    <ng-content select="description"></ng-content>
  </p>
</div>
