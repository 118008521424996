<!-- begin::Header -->
<div class="card-header border-0 pt-5">
  <h3 class="card-title align-items-start flex-column">
    <span class="card-label fw-bolder fs-3 mb-1">Latest Arrivals</span>
    <span class="text-muted mt-1 fw-bold fs-7">More than 100 new products</span>
  </h3>
  <div class="card-toolbar">
    <!-- begin::Menu -->
    <button
      type="button"
      class="btn btn-sm btn-icon btn-color-primary btn-active-light-primary"
      data-kt-menu-trigger="click"
      data-kt-menu-placement="bottom-end"
      data-kt-menu-flip="top-end"
    >
      <span
        [inlineSVG]="'./assets/media/icons/duotune/general/gen024.svg'"
        class="svg-icon svg-icon-2"
      ></span>
    </button>
    <app-dropdown-menu1></app-dropdown-menu1>
    <!-- end::Menu -->
  </div>
</div>
<!-- end::Header -->
<!-- begin::Body -->
<div class="card-body py-3">
  <!-- begin::Table container -->
  <div class="table-responsive">
    <!-- begin::Table -->
    <table class="table align-middle gs-0 gy-5">
      <!-- begin::Table head -->
      <thead>
        <tr>
          <th class="p-0 w-50px"></th>
          <th class="p-0 min-w-150px"></th>
          <th class="p-0 min-w-150px"></th>
          <th class="p-0 min-w-125px"></th>
          <th class="p-0 min-w-40px"></th>
        </tr>
      </thead>
      <!-- end::Table head -->
      <!-- begin::Table body -->
      <tbody>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/plurk.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Top Authors
            </a>
            <span class="text-muted fw-bold d-block fs-7"
              >Successful Fellas</span
            >
          </td>
          <td class="text-end">
            <span class="badge badge-light-danger fw-bold me-1">Angular</span>
            <span class="badge badge-light-info fw-bold me-1">PHP</span>
          </td>
          <td class="text-end">
            <span class="text-muted fw-bold">4600 Users</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/telegram.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Popular Authors
            </a>
            <span class="text-muted fw-bold d-block fs-7">Most Successful</span>
          </td>
          <td class="text-end">
            <span class="badge badge-light-danger fw-bold me-1">HTML</span>
            <span class="badge badge-light-info fw-bold me-1">CSS</span>
          </td>
          <td class="text-end">
            <span class="text-muted fw-bold">7200 Users</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/vimeo.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              New Users
            </a>
            <span class="text-muted fw-bold d-block fs-7">Awesome Users</span>
          </td>
          <td class="text-end">
            <span class="badge badge-light-danger fw-bold me-1">React</span>
            <span class="badge badge-light-info fw-bold me-1">SASS</span>
          </td>
          <td class="text-end">
            <span class="text-muted fw-bold">890 Users</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/bebo.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Active Customers
            </a>
            <span class="text-muted fw-bold d-block fs-7">Best Customers</span>
          </td>
          <td class="text-end">
            <span class="badge badge-light-danger fw-bold me-1">Java</span>
            <span class="badge badge-light-info fw-bold me-1">PHP</span>
          </td>
          <td class="text-end">
            <span class="text-muted fw-bold">6370 Users</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
        <tr>
          <td>
            <div class="symbol symbol-50px me-2">
              <span class="symbol-label">
                <img
                  src="./assets/media/svg/brand-logos/kickstarter.svg"
                  class="h-50 align-self-center"
                  alt=""
                />
              </span>
            </div>
          </td>
          <td>
            <a
              href="#"
              class="text-dark fw-bolder text-hover-primary mb-1 fs-6"
            >
              Bestseller Theme
            </a>
            <span class="text-muted fw-bold d-block fs-7"
              >Amazing Templates</span
            >
          </td>
          <td class="text-end">
            <span class="badge badge-light-danger fw-bold me-1">Python</span>
            <span class="badge badge-light-info fw-bold me-1">MySQL</span>
          </td>
          <td class="text-end">
            <span class="text-muted fw-bold">354 Users</span>
          </td>
          <td class="text-end">
            <a
              href="#"
              class="btn btn-sm btn-icon btn-bg-light btn-active-color-primary"
            >
              <span
                [inlineSVG]="'./assets/media/icons/duotune/arrows/arr064.svg'"
                class="svg-icon svg-icon-2"
              ></span>
            </a>
          </td>
        </tr>
      </tbody>
      <!-- end::Table body -->
    </table>
    <!-- end::Table -->
  </div>
  <!-- end::Table container -->
</div>
<!-- end::Body -->
