import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { InlineSVGModule } from 'ng-inline-svg-2';
import { NgxPaginationModule } from 'ngx-pagination';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { NgxEditorModule } from 'ngx-editor';
// import { NgxIntlTelInputModule } from 'ngx-intl-tel-input';
import { TagInputModule } from 'ngx-chips';
import { MatIconModule } from '@angular/material/icon';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { CompleteregComponent } from './overview/completereg.component';
import { CompleteregRoutingModule } from './completereg.routing.module';
import { ModalComponent } from './modal/modal.component';
import { BuySOLComponent } from './buySOL/buySOL.component';

@NgModule({
  declarations: [
   CompleteregComponent,
   ModalComponent,
   BuySOLComponent
  ],
  imports: [
    CommonModule,
    InlineSVGModule,
    FormsModule,
    NgxPaginationModule,
    NgxIntlTelInputModule,
    ReactiveFormsModule,
    CompleteregRoutingModule,
    NgbModule,
    NgMultiSelectDropDownModule.forRoot(),
    NgxEditorModule,
    TagInputModule,
    MatIconModule
  ]
})
export class CompleteregModule { }
